<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
      >
        <p class="text-button">Cliend Option *</p>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          dense
          v-model="s3Keeper.M.ClientOption.M.aws_access_key_id.S"
          color="blue darken-2"
          label="AWS Access Key ID"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          dense
          v-model="s3Keeper.M.ClientOption.M.aws_secret_access_key.S"
          color="blue darken-2"
          label="AWS Secret Access Key"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
      >
        <v-textarea
          v-model="s3Keeper.M.ClientOption.M.aws_session_token.S"
        >
          <template v-slot:label>
            <div>
              AWS Session Token <small></small>
            </div>
          </template>
        </v-textarea>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          dense
          v-model="s3Keeper.M.ClientOption.M.region_name.S"
          color="blue darken-2"
          label="Region Name"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          dense
          v-model="s3Keeper.M.ClientOption.M.api_version.S"
          color="blue darken-2"
          label="API Version"
        ></v-text-field>
      </v-col>
      <v-col
        class="mt-n3"
        cols="12"
        sm="2"
      >
        <v-checkbox
          v-model="s3Keeper.M.ClientOption.M.use_ssl.BOOL"
          label="Use SSL"
        ></v-checkbox>
      </v-col>
      <v-col
        class="mt-n3"
        cols="12"
        sm="2"
      >
        <v-checkbox
          v-model="s3Keeper.M.ClientOption.M.verify.BOOL"
          label="Verify"
        ></v-checkbox>
      </v-col>
      <v-col
        cols="12"
        sm="8"
      >
        <v-text-field
          dense
          v-model="s3Keeper.M.ClientOption.M.endpoint_url.S"
          label="Endpoint URL"
          :rules="[fieldRequire]"
          required
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
      >
        <p class="text-button">Put Object Option *</p>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-select
          dense
          v-model="s3Keeper.M.PutObjectOption.M.ACL.S"
          :items="endpointEnum.acl"
          label="ACL"
        ></v-select>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-select
          dense
          v-model="s3Keeper.M.PutObjectOption.M.Body.S"
          :items="endpointEnum.body"
          label="Body"
        ></v-select>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          dense
          v-model="s3Keeper.M.PutObjectOption.M.Bucket.S"
          label="Bucket *"
          :rules="[fieldRequire]"
          required
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          dense
          v-model="s3Keeper.M.PutObjectOption.M.CacheControl.S"
          label="Cache Control"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          dense
          v-model="s3Keeper.M.PutObjectOption.M.ContentDisposition.S"
          label="Content Disposition"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          dense
          v-model="s3Keeper.M.PutObjectOption.M.ContentEncoding.S"
          label="Content Encoding"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          dense
          v-model="s3Keeper.M.PutObjectOption.M.ContentLanguage.S"
          label="Content Language"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          dense
          v-model.number="s3Keeper.M.PutObjectOption.M.ContentLength.S"
          label="Content Length"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          dense
          v-model="s3Keeper.M.PutObjectOption.M.ContentMD5.S"
          label="Content MD5"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          dense
          v-model="s3Keeper.M.PutObjectOption.M.ContentType.S"
          label="Content Type"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-menu
          v-model="menuExpires"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
              <!-- :value="s3Keeper.M.PutObjectOption.M.Expires.L" -->
            <v-text-field
              :value="s3Keeper.M.PutObjectOption.M.Expires.L"
              label="Expires"
              hint="[yyyy, mm, dd] format"
              prepend-icon="mdi-calendar"
              readonly
              dense
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="expires"
            :min="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
             @input="menuExpires = false"
          ></v-date-picker>
        </v-menu>
        <!-- <v-text-field
          dense
          label="Expires"
        ></v-text-field> -->
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          dense
          v-model="s3Keeper.M.PutObjectOption.M.GrantFullControl.S"
          label="Grant Full Control"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          dense
          v-model="s3Keeper.M.PutObjectOption.M.GrantReadACP.S"
          label="Grant Read ACP"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          dense
          v-model="s3Keeper.M.PutObjectOption.M.GrantWriteACP.S"
          label="Grant Write ACP"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
      >
        <v-text-field
          dense
          v-model="s3Keeper.M.PutObjectOption.M.Key.S"
          label="Key Prefix *"
          :rules="[fieldRequire]"
          required
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="2"
      >
        <div class="subtitle-1 text--secondary">Metadata</div>
      </v-col>
      <v-col
        cols="12"
        sm="10"
      >
        <v-row
          class="mt-n4"
          v-for="(value, key, index) in s3Keeper.M.PutObjectOption.M.Metadata.M"
          :key="index"
        >
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              dense
              :value="key"
              label="Key"
              :rules="[fieldRequire]"
              required
              @blur="s3Keeper.M.PutObjectOption.M.Metadata.M = editObjectKey(s3Keeper.M.PutObjectOption.M.Metadata.M, $event.target.value, key)"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              dense
              :value="value.S"
              label="Value"
              :rules="[fieldRequire]"
              required
              @blur="s3Keeper.M.PutObjectOption.M.Metadata.M = editObjectValue(s3Keeper.M.PutObjectOption.M.Metadata.M, $event.target.value, key)"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row
          class="mt-n4"
        >
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              dense
              label="Key"
              v-model="objectKey"
              @blur="s3Keeper.M.PutObjectOption.M.Metadata.M = addNewObjectKey(s3Keeper.M.PutObjectOption.M.Metadata.M, $event.target.value)"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              dense
              v-model="objectValue"
              label="Value"
              @blur="s3Keeper.M.PutObjectOption.M.Metadata.M = addNewObjectValue(s3Keeper.M.PutObjectOption.M.Metadata.M, $event.target.value)"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          dense
          v-model="s3Keeper.M.PutObjectOption.M.ServerSideEncryption.S"
          label="Server Side Encryption"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-select
          dense
          v-model="s3Keeper.M.PutObjectOption.M.StorageClass.S"
          :items="endpointEnum.storageClass"
          label="Storage Class"
        ></v-select>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          dense
          v-model="s3Keeper.M.PutObjectOption.M.WebsiteRedirectLocation.S"
          label="Website Redirect Location"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          dense
          v-model="s3Keeper.M.PutObjectOption.M.SSECustomerAlgorithm.S"
          label="SSE Customer Algorithm"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          dense
          v-model="s3Keeper.M.PutObjectOption.M.SSECustomerKey.S"
          label="SSE Customer Key"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          dense
          v-model="s3Keeper.M.PutObjectOption.M.SSEKMSKeyId.S"
          label="SSEKMS Key Id"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          dense
          v-model="s3Keeper.M.PutObjectOption.M.SSEKMSEncryptionContext.S"
          label="SSEKMS Encryption Context"
        ></v-text-field>
      </v-col>
      <v-col
        class="mt-n3"
        cols="12"
        sm="6"
      >
        <v-checkbox
          v-model="s3Keeper.M.PutObjectOption.M.BucketKeyEnabled.S"
          label="Bucket Key Enabled"
        ></v-checkbox>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          dense
          v-model="s3Keeper.M.PutObjectOption.M.RequestPayer.S"
          label="Request Payer"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          dense
          v-model="s3Keeper.M.PutObjectOption.M.Tagging.S"
          label="Tagging"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-select
          dense
          v-model="s3Keeper.M.PutObjectOption.M.ObjectLockMode.S"
          :items="endpointEnum.objectLockMode"
          label="Object LockMode"
        ></v-select>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-menu
          v-model="menuObjectLockRetainUntilDate"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="s3Keeper.M.PutObjectOption.M.ObjectLockRetainUntilDate.L"
              label="Object Lock Retain Until Date"
              hint="[yyyy, mm, dd] format"
              prepend-icon="mdi-calendar"
              readonly
              dense
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="objectLockRetainUntilDate"
            :min="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
             @input="menuObjectLockRetainUntilDate = false"
          ></v-date-picker>
        </v-menu>
        <!-- <v-text-field
          dense
          v-model="s3Keeper.M.PutObjectOption.M.ObjectLockRetainUntilDate.S"
          label="Object Lock Retain Until Date"
          required
        ></v-text-field> -->
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-select
          dense
          v-model="s3Keeper.M.PutObjectOption.M.ObjectLockLegalHoldStatus.S"
          :items="endpointEnum.objectLockLegalHoldStatus"
          label="Object Lock Legal Hold Status"
        ></v-select>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          dense
          v-model="s3Keeper.M.PutObjectOption.M.ExpectedBucketOwner.S"
          label="Expected Bucket Owner"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import endpointTemplateForm from '@/template/endpointTemplate.json'

export default {
  name: 'ProcessorS3KeeperForm',
  model: {
    prop: 's3Keeper'
  },
  props: {
    s3Keeper: {
      type: Object,
      default () {
        return {
        }
      },
      required: true
    }
  },
  data: () => ({
    objectKey: null,
    objectValue: null,
    menuObjectLockRetainUntilDate: false,
    menuExpires: false,
    activePicker: null
  }),
  computed: {
    endpointEnum: function () {
      return endpointTemplateForm.enum.s3Keeper
    },
    fieldRequire: function () {
      return v => !!v || 'field is required'
    },
    counterRequire: function (lessLength, moreLength) {
      if (lessLength && moreLength) {
        return v => ((v || '').length >= lessLength && (v || '').length <= moreLength) || `field must be ${lessLength}-${moreLength} characters`
      } else {
        return v => (v || '').length <= lessLength || `field must be ${lessLength} characters`
      }
    },
    objectLockRetainUntilDate: {
      get: function () {
        let objLockRetain = null
        if (this.s3Keeper.M.PutObjectOption.M.ObjectLockRetainUntilDate.L.length > 0) {
          // objLockRetain = this.s3Keeper.M.PutObjectOption.M.ObjectLockRetainUntilDate.L[0].replace(/\s/g, '').replace(/,/g, '-')
          objLockRetain = this.s3Keeper.M.PutObjectOption.M.ObjectLockRetainUntilDate.L.join('-')
        }
        return objLockRetain
      },
      set: function (value) {
        this.s3Keeper.M.PutObjectOption.M.ObjectLockRetainUntilDate.L = value.split(/-/g)
      }
    },
    expires: {
      get: function () {
        let putObjectExpires = null
        if (this.s3Keeper.M.PutObjectOption.M.Expires.L.length > 0) {
          // putObjectExpires = this.s3Keeper.M.PutObjectOption.M.Expires.L[0].replace(/\s/g, '').replace(/,/g, '-')
          putObjectExpires = this.s3Keeper.M.PutObjectOption.M.Expires.L.join('-')
        }
        return putObjectExpires
      },
      set: function (value) {
        this.s3Keeper.M.PutObjectOption.M.Expires.L = value.split(/-/g)
        // this.s3Keeper.M.PutObjectOption.M.Expires.L = [value.replace(/-/g, ',')]
      }
    }
  },
  methods: {
    editObjectKey: function (object, newKey, oldKey) {
      let newObject = Object.assign({}, object)
      if (newKey !== oldKey) {
        if (!newKey && !newObject[oldKey].S) {
          this.$delete(newObject, oldKey)
        } else {
          newObject = {}
          for (const [key, value] of Object.entries(object)) {
            if (key === oldKey) {
              newObject[newKey] = value
            } else {
              newObject[key] = value
            }
          }
        }
      }
      return newObject
    },
    editObjectValue: function (object, key, value) {
      let newObject = Object.assign({}, object)
      if (!key && !value) {
        this.$delete(newObject, key)
      } else {
        newObject[key] = { S: value }
      }
      return newObject
    },
    addNewObjectKey: function (object, key) {
      let newObject = Object.assign({}, object)
      if (key) {
        this.$set(newObject, key, { S: '' })
      }
      this.objectKey = null
      return newObject
    },
    addNewObjectValue: function (object, value) {
      let newObject = Object.assign({}, object)
      if (value) {
        this.$set(newObject, `key-${Object.keys(newObject).length}`, { S: value })
      }
      this.objectValue = null
      return newObject
    },
    // objectLockRetainUntilDateFormat: function (value) {
    //   return [value.replace(/-/g, ',')]
    // },
    ttt (val) {
      console.log(val)
    }
  }
}
</script>
